import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/global/layout"
import PostGrid from "../components/global/PostGrid"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/sanity/serializers"
import { DetectScroll } from "../utils/DetectScroll"
export default function AllRecipes({ data }) {
  const posts = data.allSanityRecipes.nodes

  return (
    <Layout>
      <SearchEngineOptimizaion
        title={
          data.sanityPages.seo.seoTitle !== ""
            ? data.sanityPages.seo.seoTitle
            : "Gatrologik | Mad med mening"
        }
        description={
          data.sanityPages.seo.description !== ""
            ? data.sanityPages.seo.description
            : "Gatrologik | Mad med mening"
        }
      />
      <div className="titleWrapper">
        <h1>{data.sanityPages.pageTitle}</h1> <div className="spanLine"></div>
        <BlockContent
          blocks={data.sanityPages._rawBody}
          projectId="2aoj8j2d"
          dataset="gastrologik"
          serializers={serializers}
          className="recipeBlockContent"
        />
      </div>

      <PostGrid posts={DetectScroll(posts)} />
    </Layout>
  )
}

export const query = graphql`
  query allRecipesQuery {
    allSanityRecipes(sort: { publishedAt: DESC }) {
      nodes {
        _rawBody

        title

        image: recipeImage {
          verticalImage {
            asset {
              gatsbyImageData(
                backgroundColor: "#f9f9f9"
                placeholder: NONE
                width: 280
                height: 366
              )
            }
          }
        }
        slug {
          current
        }
        time
      }
    }

    sanityPages(slug: { current: { eq: "alle-opskrifter" } }) {
      pageTitle
      _rawBody
      seo {
        seoTitle
        seoDescription
      }
    }
  }
`
