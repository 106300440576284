import { useEffect, useState } from "react"

export function DetectScroll(posts) {
  const [state, setstate] = useState(posts.slice(0, 12))

  useEffect(() => {
    window.addEventListener("scroll", scrolled)

    function scrolled() {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 0
      ) {
        setstate(prevState => [...prevState, ...posts.slice(12)])
      }
    }
  }, [posts, state])

  return state
}
